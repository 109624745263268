import React from "react";
import { Index } from "../screens/Index/Index";
import { Page } from "../components/Page";
import { Mobile } from "../screens/Mobile/Mobile";

export default function RapBeatRoute() {
  return (
    <Page>
      <Index route='rapbeat' />
      <Mobile />
    </Page>
  );
}
